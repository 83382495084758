@import-normalize;
html,
body,
#root {
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  overflow: auto;
}

.domain {
  display: none;
}

.tick line {
  stroke-dasharray: 2, 2;
  stroke: #c2c2c2;
}

#notistack-snackbar + div {
  padding-left: 0;
}

/* .tick text {
  font-family: 'PF Din Text';
} */

/*RESET CSS*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* TODO: move to the right place */
.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.page-appear {
  opacity: 0;
}

.page-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 100ms ease-out;
}

/* TODO: move to the right place */
.nav-link {
  text-decoration: none;
}

.annotation-note-label {
  font-size: 12px;
  fill: #4a4a4a;
}

.annotation-note-title {
  fill: #4a4a4a;
}

.annotation tspan,
.annotation text,
.annotation path {
  pointer-events: none;
}

.annotation-note-bg {
  fill-opacity: 1;
  fill: white;
}

.circleSelection {
  height: 0;
  overflow: hidden;
  position: absolute;
}

.MuiCardHeader-title {
  font-family: 'Source Sans Pro', sans-serif !important;
  text-decoration: none;
  font-size: 16px !important;
}

.MuiCardHeader-subheader {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 16px !important;
  max-width: 240px !important;
  line-height: 1.3 !important;
}

.MuiCardHeader-action {
  align-self: auto !important;
}

#mainHeader img {
  width: 150px;
  height: auto;
}

@media (max-width: 890px) {
  #mainHeader img {
    width: 100px;
    height: auto;
  }
}

/* portrait */
@media screen and (orientation: portrait) {
  /* portrait-specific styles */
  .mobile-message {
    display: flex;
  }
}
/* landscape */
@media screen and (orientation: landscape) {
  /* landscape-specific styles */
  .mobile-message {
    display: none;
  }
}
/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobile-message {
    display: none;
  }
  #mainHeader img {
    width: 130px;
    height: auto;
  }
}
